import api from "../../../utils/aixos";

export const getPlaylistsAPICall = async () =>
  api.get("/genrate-youtube-subtitles/get-list-playlist");

export const translateFoldersAPICall = async ({
  playlistId,
  targetLanguages,
}: {
  playlistId: string;
  targetLanguages: string[];
}) =>
  api.post("/genrate-youtube-subtitles/playlist", {
    playlistId,
    targetLanguages,
  });

export const bulkTranslateAPICall = async ({
  videoUrlArray,
  targetLanguages,
  email,
  token,
}: {
  videoUrlArray: string[];
  targetLanguages: string[];
  email: string;
  token: string;
}) =>
  api.post("/genrate-youtube-subtitles/bulk", {
    videoUrlArray,
    targetLanguages,
    email,
    token,
  });

export const autoUploadAPICall = async ({
  isTrue,
  targetLanguages,
}: {
  isTrue: boolean;
  targetLanguages: string[];
}) =>
  api.post("/genrate-youtube-subtitles/auto-genrate", {
    isTrue,
    targetLanguages,
  });
