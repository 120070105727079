import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUserAPICall, loginAPICall } from "./auth.api";
import { showErrorToast, showSuccessToast } from "../../../utils/toast";

export interface AutoGenerateConfig {
  genrate?: boolean;
  targetLanguages: string[];
}
export interface Payment {
  paymentMethod: string;
  customerId: string;
  subscriptionId: string;
  priceId: string;
  validUntil: string;
  lastFourDigit: string;
  cardBrand: string;
  active: boolean;
}
export interface GoogleOAuth {
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  expiry_date: number;
}
export interface User {
  email: string;
  id: string;
  firstName?: string;
  lastName?: string;
  vimeoAccessToken?: string;
  autoGenrateConfig?: AutoGenerateConfig;
  autoGenrateConfigYoutube?: AutoGenerateConfig;
  payment?: Payment;
  googleOAuth?: GoogleOAuth;
}

export interface AuthState {
  token?: string;
  user?: User;
}

const initialState: AuthState = {};

// Thunk actions
const login = createAsyncThunk(
  `auth/loginStatus`,
  async ({ email, password }: { email: string; password: string }) => {
    const response = await loginAPICall(email, password);
    return response.data;
  }
);

export const fetchUser = createAsyncThunk("auth/fetchUserStatus", async () => {
  const response = await fetchUserAPICall();
  return response.data;
});

export const authSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = undefined;
      state.user = undefined;
    },
    setAuth: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload.data.token) {
        showSuccessToast("Login successful!");
      } else {
        showErrorToast("Login failed!");
      }
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
    });
  },
});

// Action creators are generated for each case reducer function
export const { logout, setAuth } = authSlice.actions;

export default authSlice.reducer;
