import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Input, { Option } from "../../components/Input";
import { RootState } from "../../redux/store";
import * as yup from "yup";
import languages from "../../constants/languages";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import SelectableList from "../../components/SelectableList";

import MultiInput from "../../components/MultiInput";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import { AxiosError } from "axios";
import {
  bulkTranslateAPICall,
  translateFoldersAPICall,
  autoUploadAPICall,
} from "../../redux/slices/youtube/youtube.api";

const FoldersSection = () => {
  const { folders, user } = useSelector((state: RootState) => ({
    folders: state.youtube.playlists,
    user: state.auth.user,
  }));

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: FieldValues) => {
    try {
      await translateFoldersAPICall({
        playlistId: values.folder.id,
        targetLanguages: values.languagesToConvert.map(
          (lang: Option) => lang.value
        ),
      });
      showSuccessToast("Translation initiated successfully!");
    } catch (error) {
      if (error instanceof AxiosError) {
        showErrorToast(error.response?.data.error);
      }
    }
  };
  return (
    <div className="basis-3/3 basis-1/3 border-2 rounded-md p-4 h-fit">
      <h2 className="font-bold text-xl">Folders Upload</h2>
      {user?.googleOAuth?.access_token ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectableList
            icon={<FontAwesomeIcon icon={faFolder} className="text-primary" />}
            labelKey="name"
            valueKey="id"
            name="folder"
            options={folders}
            control={control}
          />

          <div className="w-full py-2 ">
            <Input
              type="select"
              control={control}
              name="languagesToConvert"
              error={errors?.languagesToConvert?.message?.toString() || ""}
              label={"Languages"}
              options={languages.map((country) => ({
                label: country.name,
                value: country.code,
              }))}
              placeholder={"Select languages"}
              isMulti
            />
          </div>
          <Button loading={isSubmitting}>Translate</Button>
        </form>
      ) : (
        <div>
          {" "}
          Please add a token in{" "}
          <Link className="text-primary" to={"/settings"}>
            Settings
          </Link>
        </div>
      )}
    </div>
  );
};

const AutoUploadSection = () => {
  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }));

  const {
    control,
    handleSubmit,

    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(autoUploadSchema),
  });

  const onSubmit = async (values: FieldValues) => {
    try {
      await autoUploadAPICall({
        isTrue: values.autoupload,
        targetLanguages: values.languagesToConvert.map(
          (lang: Option) => lang.value
        ),
      });
      showSuccessToast("Settings saved successfully!");
    } catch (error) {
      if (error instanceof AxiosError) {
        showErrorToast(error.response?.data.error);
      }
    }
  };

  useEffect(() => {
    if (user?.autoGenrateConfigYoutube) {
      reset({
        autoupload: user?.autoGenrateConfigYoutube?.genrate,
        languagesToConvert: languages
          .filter((e) =>
            user.autoGenrateConfigYoutube?.targetLanguages.includes(e.code)
          )
          .map((country) => ({
            label: country.name,
            value: country.code,
          })),
      });
    }
  }, [user]);
  return (
    <div className="basis-3/3 basis-1/3 border-2 rounded-md p-4 h-fit">
      <h2 className="font-bold text-xl">Autoupload Upload</h2>
      {user?.googleOAuth?.access_token ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full py-2">
            <Input
              name="autoupload"
              type="checkbox"
              control={control}
              label="Autoupload new videos"
            />
          </div>

          <div className="w-full py-2 ">
            <Input
              type="select"
              control={control}
              name="languagesToConvert"
              error={errors?.languagesToConvert?.message?.toString() || ""}
              label={"Languages"}
              options={languages.map((country) => ({
                label: country.name,
                value: country.code,
              }))}
              placeholder={"Select languages"}
              isMulti
            />
          </div>
          <Button loading={isSubmitting}>Apply Changes</Button>
        </form>
      ) : (
        <div>
          {" "}
          Please add a token in{" "}
          <Link className="text-primary" to={"/settings"}>
            Settings
          </Link>
        </div>
      )}
    </div>
  );
};

const BulkUploadSection = () => {
  const { user, token } = useSelector((state: RootState) => ({
    user: state.auth.user,
    token: state.auth.token,
  }));

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(bulkUploadSchema),
  });

  const onSubmit = async (values: FieldValues) => {
    try {
      await bulkTranslateAPICall({
        token: String(token),
        email: String(values?.email),
        targetLanguages: values.languagesToConvert.map(
          (lang: Option) => lang.value
        ),
        videoUrlArray: values.link,
      });
      showSuccessToast(
        "Translation successfully started! The video should be updated automatically."
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        showErrorToast(error.response?.data.error);
      }
    }
    reset();
  };
  const {
    linklabel,
    linkplaceholder,
    emaillabel,
    emailplaceholder,
    languagelabel,
    languageplaceholder,
  } = useSelector((state: RootState) => state.content.content);

  useEffect(() => {
    if (user) {
      reset({ email: user.email });
    }
  }, [user]);
  return (
    <div className="basis-3/3 basis-1/3 border-2 rounded-md p-4">
      <h2 className="font-bold text-xl">Bulk Upload</h2>
      {user?.googleOAuth?.access_token ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full py-2 ">
            <MultiInput
              control={control}
              name="link"
              label={linklabel}
              placeholder={linkplaceholder}
              platform="youtube"
            />
          </div>
          <div className="w-full py-2 ">
            <Input
              type="text"
              register={register}
              name="email"
              error={errors?.email?.message?.toString() || ""}
              label={emaillabel}
              placeholder={emailplaceholder}
            />
          </div>
          <div className="w-full py-2 ">
            <Input
              type="select"
              control={control}
              name="languagesToConvert"
              error={errors?.languagesToConvert?.message?.toString() || ""}
              label={languagelabel}
              options={languages.map((country) => ({
                label: country.name,
                value: country.code,
              }))}
              placeholder={languageplaceholder}
              isMulti
            />
          </div>
          <Button loading={isSubmitting}>Translate</Button>
        </form>
      ) : (
        <div>
          {" "}
          Please add a token in{" "}
          <Link className="text-primary" to={"/settings"}>
            Settings
          </Link>
        </div>
      )}
    </div>
  );
};

const YoutubeDashboard = () => {
  const {
    content: { dashboardWelcomeMessage },
  } = useSelector((state: RootState) => ({
    content: state.content.content,
  }));
  return (
    <div className="p-8">
      <div className="my-2">
        <div
          style={{
            fontFamily: "Paytone One",
            fontSize: 32,
          }}
          className="w-full lg:w-2/5"
        >
          {dashboardWelcomeMessage}
        </div>
      </div>
      <div className="flex gap-3">
        <FoldersSection />
        <BulkUploadSection />
        <AutoUploadSection />
      </div>
    </div>
  );
};

export default YoutubeDashboard;

const schema = new yup.ObjectSchema({
  folder: yup.mixed().test("folder-test", (value) => {
    if (!value || !value.id) return false;
    return true;
  }),
  languagesToConvert: yup
    .array()
    .typeError("Please select a language")
    .min(1, "Please select a language")
    .required("Please select a language"),
});

const bulkUploadSchema = new yup.ObjectSchema({
  email: yup
    .string()
    .email("Please enter an email address")
    .required("Please enter an email address"),
  link: yup
    .array(yup.string())
    .min(1, "Please enter atleast one link")
    .required("Please enter atleast one link"),
  languagesToConvert: yup
    .array()
    .typeError("Please select a language")
    .min(1, "Please select a language")
    .required("Please select a language"),
});

const autoUploadSchema = new yup.ObjectSchema({
  isTrue: yup.bool(),
  languagesToConvert: yup
    .array()
    .typeError("Please select a language")
    .min(1, "Please select a language")
    .required("Please select a language"),
});
