import React, { useEffect, useMemo, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import Button from "../../components/Button";
import {
  cancelSubscriptionApiCall,
  updatePaymentMethodApiCall,
} from "../../redux/services/payment";
import { fetchUser } from "../../redux/slices/auth/auth.slice";
import moment from "moment";
import Visa from "../../assets/images/credit-cards/visa.svg";
import Amex from "../../assets/images/credit-cards/amex.svg";
import Mastercard from "../../assets/images/credit-cards/mastercard.svg";
import Unknown from "../../assets/images/credit-cards/unknown.svg";
import Modal from "../../components/Modal";
import api from "../../utils/aixos";
import Loader from "../../components/Loader/Loader";

const paymentInfo = {
  "local-video-price": "Local Video ",
};

function PaymentInformation() {
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<any>({});
  const stripe = useStripe();
  const elements = useElements();
  const [editMode, setEditMode] = useState<Boolean>(false);
  const [cancelModal, setCancelModal] = useState<Boolean>(false);
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch<AppDispatch>();
  const onSubmit: () => Promise<void> = async () => {
    const response = await stripe?.createPaymentMethod({
      type: "card",
      card: elements?.getElement(CardElement)!,
    });
    const res = await updatePaymentMethodApiCall(
      response?.paymentMethod?.id || ""
    );
    dispatch(fetchUser());
    setEditMode(false);
  };
  const cancel = async () => {
    await cancelSubscriptionApiCall();
    dispatch(fetchUser());
    setCancelModal(false);
  };
  const logo = useMemo(() => {
    if (!user?.payment?.cardBrand) return Unknown;
    return CREDIT_CARD_LOGOS[user?.payment?.cardBrand] || Unknown;
  }, [user]);

  console.log(
    invoice?.lines?.data?.find(
      (el: any) => el.price.nickname === "platform-video"
    )
  );

  const getUpcommingInvoice = async () => {
    setLoading(true);
    const response = await api.get("auth/get-upcomming-invoice");
    setInvoice(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getUpcommingInvoice();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="p-8">
        <h1 className="text-2xl font-bold mb-8">Payment Information</h1>
        {editMode ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="w-full p-4 border-2 rounded-md">
              <CardElement />
            </div>
            <Button>Update</Button>
          </form>
        ) : (
          <div className="flex">
            <div className="flex items-center justify-between flex-1">
              <div className="flex items-center">
                Your current card is a{" "}
                <img
                  alt={user?.payment?.cardBrand}
                  className="w-8 mx-2"
                  src={logo}
                />
                card ending in {user?.payment?.lastFourDigit} and subscription
                is valid until{" "}
                {moment
                  .unix(Number(user?.payment?.validUntil) / 1000)
                  .format("DD/MM/YYYY")}
                .
              </div>

              {user?.payment?.active && (
                <Button
                  onClick={async () => {
                    setEditMode(true);
                  }}
                  className="bg-primary p-2 w-20 text-white rounded-md font-semibold text-sm ml-2"
                >
                  Update
                </Button>
              )}
            </div>
            {user?.payment?.active ? (
              <Button
                onClick={() => setCancelModal(true)}
                className="ml-2 bg-red-600 p-2 w-20 text-white rounded-md font-semibold text-sm"
              >
                Cancel
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  setEditMode(true);
                }}
                className="ml-2 bg-primary p-2 px-4 w-fit text-white rounded-md font-semibold text-sm"
              >
                Reactivate
              </Button>
            )}
          </div>
        )}
        <Modal
          setShow={(value: Boolean) => setCancelModal(value)}
          show={cancelModal}
        >
          <p className="text-lg mb-4 text-center font-semibold">
            Are you sure you want to cancel your subscription!
          </p>
          <div className="flex flex-row gap-2">
            <Button
              onClick={cancel}
              className=" bg-red-600 p-2 w-full text-white rounded-md font-semibold "
            >
              Yes
            </Button>
            <Button
              onClick={() => setCancelModal(false)}
              className=" bg-primary p-2 w-full text-white rounded-md font-semibold "
            >
              No
            </Button>
          </div>
        </Modal>
        <div className="mt-6">
          <div>
            <div>
              <span className="text-2xl font-bold">Upcoming invoice </span>
            </div>
            <div className="mb-4">
              <span>Next invoice for subscription to </span>
              <span className="text-blue-600">LetzChat</span>
              <span> | will be billed on </span>
              <span className="font-medium">
                {new Date(
                  invoice?.next_payment_attempt * 1000
                ).toLocaleDateString("en-US")}
              </span>
              <span className="text-center text-red-600">
                <p>
                  * Free 600 second's of local video and 600 seconds of platform
                  videos
                </p>
              </span>
            </div>
          </div>
          <div>
            <div className="text-1xl font-bold mb-2">Summary</div> <hr />
            <div className="grid grid-cols-2">
              <div>
                <table className="table-auto">
                  <tr>
                    <td className="font-normal text-gray-500">Bill to</td>
                    <td className="pl-10">{invoice?.customer_email}</td>
                  </tr>
                  <tr>
                    <td className="font-normal text-gray-500">
                      Billing Details
                    </td>
                    <td className="pl-10">{invoice?.customer_name}</td>
                  </tr>
                </table>
              </div>
              <div>
                <table className="table-auto">
                  <tr>
                    <td className="font-normal text-gray-500">Currency</td>
                    <td className="pl-10">USD - US Dollar</td>
                  </tr>
                  <tr>
                    <td className="font-normal text-gray-500">
                      Billing method
                    </td>
                    <td className="pl-10">Charge default payment method</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div>
            <table className="min-w-full divide-y divide-slate-500 mt-2">
              <thead>
                <tr>
                  <th className="py-3.5 pl-4 pr-3 text-left text-xs font-bold text-slate-700 sm:pl-6 md:pl-0">
                    DESCRIPTION
                  </th>
                  <th className="py-3.5 px-3 text-right text-xs font-bold text-slate-700 sm:table-cell">
                    QTY
                  </th>
                  <th className="py-3.5 px-3 text-right text-xs font-bold text-slate-700 sm:table-cell">
                    UNIT PRICE
                  </th>
                  <th className="py-3.5 px-3 text-right text-xs font-bold text-slate-700 sm:table-cell">
                    AMOUNT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-slate-200">
                  <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                    {new Date(invoice?.period_start * 1000).toLocaleDateString(
                      "en-US"
                    )}{" "}
                    -{" "}
                    {new Date(invoice?.period_end * 1000).toLocaleDateString(
                      "en-US"
                    )}
                  </td>
                </tr>
                {/* <tr className="border-b border-slate-200">
                  <td className="py-4 pl-4 pr-3 text-sm font-bold sm:pl-6 md:pl-0">
                    Fixed Monthly
                  </td>
                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    1
                  </td>

                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    $20
                  </td>
                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    $20
                  </td>
                </tr>
                <tr className="border-b border-slate-200">
                  <td className="py-4 pl-4 pr-3 text-sm font-bold sm:pl-6 md:pl-0">
                    Local Video second's
                  </td>
                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    {
                      invoice?.lines?.data?.find(
                        (el: any) => el.price.nickname === "local-video-price"
                      )?.quantity
                    }
                  </td>
                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    $0.02
                  </td>
                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    {
                      invoice?.lines?.data?.find(
                        (el: any) => el.price.nickname === "local-video-price"
                      )?.amount
                    }
                  </td>
                </tr>
                <tr className="border-b border-slate-200">
                  <td className="py-4 pl-4 pr-3 text-sm font-bold sm:pl-6 md:pl-0">
                    Platform Video second's
                  </td>
                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    {invoice?.lines?.data?.find(
                      (el: any) => el.price.nickname === "platform-video"
                    )?.quantity >= 1200 &&
                      invoice?.lines?.data?.find(
                        (el: any) =>
                          el.price.nickname === "local-video-price" &&
                          el?.description?.includes("Tier 2")
                      )?.quantity}
                  </td>
                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    $0.0146
                  </td>
                  <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                    {
                      invoice?.lines?.data?.find(
                        (el: any) => el.price.nickname === "platform-video"
                      )?.amount
                    }
                  </td>
                </tr> */}
                {invoice?.lines?.data?.map((el: any) => {
                  return (
                    <tr className="border-b border-slate-200">
                      <td className="py-4 pl-4 pr-3 text-sm font-bold sm:pl-6 md:pl-0">
                        {el?.description}
                      </td>
                      <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                        {el?.quantity}
                      </td>
                      <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                        {el?.description?.split("at")[2].split("/")[0]}
                      </td>
                      <td className=" px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                        ${Math.round(el?.amount / 100)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot className="t">
                <tr>
                  <th
                    colSpan={3}
                    scope="row"
                    className="pt-6 pl-6 pr-3 text-sm font-bold text-right  sm:table-cell md:pl-0"
                  >
                    Subtotal
                  </th>
                  <td className="pt-6 pl-3 pr-4 text-sm text-right font-bold  sm:pr-6 md:pr-0">
                    ${Math.round(invoice?.subtotal / 100)}
                  </td>
                </tr>
                <tr>
                  <th
                    colSpan={3}
                    scope="row"
                    className="pt-6 pl-6 pr-3 text-sm font-bold text-right  sm:table-cell md:pl-0"
                  >
                    Total
                  </th>
                  <td className="pt-6 pl-3 pr-4 text-sm text-right font-bold  sm:pr-6 md:pr-0">
                    ${Math.round(invoice?.total / 100)}
                  </td>
                </tr>
                <tr>
                  <th
                    colSpan={3}
                    scope="row"
                    className="pt-4 pl-6 pr-3 text-sm font-bold text-right  sm:table-cell md:pl-0"
                  >
                    Amount due
                  </th>

                  <td className="pt-4 pl-3 pr-4 text-sm text-right font-bold  sm:pr-6 md:pr-0">
                    ${Math.round(invoice?.amount_due / 100)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentInformation;

const CREDIT_CARD_LOGOS: { [key: string]: any } = {
  visa: Visa,
  amex: Amex,
  mastercard: Mastercard,
};
