export default function TermsConditions() {
  return (
    <div className="p-8">
      <h1 className="text-primary text-3xl font-medium mb-4">
        Terms and Conditions
      </h1>
      <p className="text-lg mb-4 leading-relaxed">
        Welcome to LetzChat! By using our service, you agree to be bound by the
        following terms and conditions.
      </p>
      <h2 className="text-2xl font-medium mb-4">Introduction</h2>
      <p className="text-lg mb-4 leading-relaxed">
        These terms and conditions govern your use of our website and services.
        By using our website and services, you accept these terms and conditions
        in full. If you disagree with these terms and conditions or any part of
        these terms and conditions, you must not use our website and services.
      </p>
      <h2 className="text-2xl font-medium mb-4">Registration and Accounts</h2>
      <p className="text-lg mb-4 leading-relaxed">
        To use certain features of our website and services, you may be required
        to register for an account and provide certain personal information. You
        agree to provide accurate and complete information when registering for
        an account and to promptly update your account information as necessary.
      </p>
      <p className="text-lg mb-4 leading-relaxed">
        You are responsible for maintaining the confidentiality of your account
        information and for all activities that occur under your account. You
        agree to immediately notify us of any unauthorized use of your account
        or any other breach of security.
      </p>
      <h2 className="text-2xl font-medium mb-4">Content</h2>
      <p className="text-lg mb-4 leading-relaxed">
        By using our service, you agree not to upload, post, or transmit any
        content that is illegal, harmful, threatening, abusive, harassing,
        defamatory, vulgar, obscene, libelous, invasive of another's privacy,
        hateful, or racially, ethnically or otherwise objectionable. You also
        agree not to upload, post or transmit any content that infringes any
        patent, trademark, trade secret, copyright or other proprietary rights
        of any party.
      </p>
      <h2 className="text-2xl font-medium mb-4">
        Vimeo and YouTube account access tokens
      </h2>
      <p className="text-lg mb-4 leading-relaxed">
        By using our service, you grant us access to your Vimeo and YouTube
        account access tokens with add and edit permissions. This will allow us
        to upload and edit subtitles on your behalf. We will not
      </p>
      <h2 className="text-2xl font-medium mb-4">
        Termination and Cancellation
      </h2>
      <p className="text-lg mb-4 leading-relaxed">
        We reserve the right to terminate or cancel your access to our website
        and services at any time and for any reason, without notice.
      </p>
    </div>
  );
}
