import { useState, useEffect } from "react";

function TopLoader({ loading }: any) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: "2px",
        backgroundColor: "DodgerBlue",
        width: `${loading ? 80 : 100}%`,
      }}
    />
  );
}
export default TopLoader;
