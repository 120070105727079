import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomeLayout from "./layouts/HomeLayout/HomeLayout";
import NotFound from "./pages/404";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/Register";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import DashboardLayout from "./layouts/DashboardLayout/DashboardLayout";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import Settings from "./pages/dashboard/Settings";
import { useEffect } from "react";
import { colors, initialContent } from "./redux/slices/content/content.slice";
import { setColor } from "./utils/helper";
import { fetchUser } from "./redux/slices/auth/auth.slice";
import { initialFolders } from "./redux/slices/vimeo/vimeo.slice";
import History from "./pages/dashboard/History";
import PaymentInformation from "./pages/dashboard/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import YoutubeDashboard from "./pages/dashboard/YoutubeDashboard";
import { getPlaylists } from "./redux/slices/youtube/youtube.slice";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PricingPlan from "./pages/PricingPlan";
import Zoom from "./pages/dashboard/Zoom";
import ZoomConfiguration from "./pages/dashboard/ZoomConfiguration";

const stripePromise = loadStripe("pk_live_5FofprIZVNtzt0Lpl5Jow25M");

export default function App() {
  const token = useSelector((state: RootState) => state.auth.token);
  const user = useSelector((state: RootState) => state.auth.user);
  const content = useSelector((state: RootState) => state.content.content);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(initialContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!content) return;
    colors.forEach((key) => {
      setColor(`--${key}`, content[key]);
    });
  }, [content]);

  useEffect(() => {
    if (token) {
      dispatch(fetchUser());
      if (user?.vimeoAccessToken) {
        dispatch(initialFolders());
      }
      if (user?.googleOAuth?.access_token) {
        dispatch(getPlaylists());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user?.googleOAuth?.access_token, user?.vimeoAccessToken]);

  return !content ? (
    <></>
  ) : (
    <div className="h-screen flex flex-col">
      <BrowserRouter>
        <Routes>
          {token ? (
            <Route path="/" element={<DashboardLayout />}>
              <Route path="/vimeo" element={<Dashboard />} />
              <Route path="/youtube" element={<YoutubeDashboard />} />
              <Route path="/zoom" element={<Zoom />} />
              <Route
                path="/zoom/configuration"
                element={<ZoomConfiguration />}
              />
              <Route path="/" element={<Home />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/pricing-plan" element={<PricingPlan />} />
              <Route
                path="/payment"
                element={
                  <Elements stripe={stripePromise}>
                    <PaymentInformation />
                  </Elements>
                }
              />
              <Route path="/history" element={<History />} />
              <Route path="*" element={<Navigate to={"/"} replace />} />
            </Route>
          ) : (
            <Route path="/" element={<HomeLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/pricing-plan" element={<PricingPlan />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:id" element={<ResetPassword />} />
              <Route
                path="/register"
                element={
                  <Elements stripe={stripePromise}>
                    <Register />
                  </Elements>
                }
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Navigate to={"/"} replace />} />
            </Route>
          )}
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
