import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../components/Logo";

type Route = {
  path: string;
  label: string;
  onClick?: () => void;
};

type Props = {
  routes: Route[];
};

const Navbar: FC<Props> = ({ routes }) => {
  const { pathname } = useLocation();
  return (
    <nav className="flex items-center px-8 pt-8 b">
      <Link to="/">
        <Logo />
      </Link>
      <div className="ml-auto space-x-4">
        {routes.map((route) => (
          <NavbarItem current={route.path === pathname} {...route} />
        ))}
      </div>
    </nav>
  );
};

const NavbarItem: FC<Route & { current: boolean }> = (props) => {
  const defaultClassname: string =
    "hover:text-primary hover:font-semibold transition-all";
  return (
    <Link
      className={
        props.current
          ? `text-primary font-semibold ${defaultClassname}`
          : defaultClassname
      }
      to={props.path}
      {...(props.onClick && { onClick: props.onClick })}
    >
      {props.label}
    </Link>
  );
};

export default Navbar;
