import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import Input from "../components/Input";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosError } from "axios";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { useNavigate, useParams } from "react-router-dom";
const ResetPassword = () => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const onSubmit: (values: FieldValues) => Promise<void> = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/verify-reset-password/${id}`,
        {
          ...values,
        }
      );
      if (res.data.msg) {
        showSuccessToast(res.data.msg);
        navigate("/login");
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        showErrorToast("Couldn't reset password!");
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex items-center flex-col justify-center p-8"
    >
      <div className="w-full pt-2 ">
        <Input
          type="password"
          register={register}
          name="password"
          error={errors?.password?.message?.toString() || ""}
          label="Password"
          placeholder="Please enter your password"
        />
      </div>
      <div className="w-full pt-2 ">
        <Input
          type="password"
          register={register}
          name="confirmPassword"
          error={errors?.confirmPassword?.message?.toString() || ""}
          label="Confirm Password"
          placeholder="Please reenter your password"
        />
      </div>
      <button
        disabled={isSubmitting}
        className="bg-primary w-full rounded-md p-4 my-4 text-white font-bold"
      >
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : (
          "Reset Password"
        )}
      </button>
    </form>
  );
};

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be min 8 characters")
    .required("Please enter an password"),
  confirmPassword: yup
    .string()
    .min(8, "Password should be min 8 characters")
    .required("Please enter your password again")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default ResetPassword;
