import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/auth/auth.slice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import contentSlice from "../slices/content/content.slice";
import vimeoSlice from "../slices/vimeo/vimeo.slice";
import { vimeoApi } from "../services/vimeo";
import youtubeSlice from "../slices/youtube/youtube.slice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authSlice,
  content: contentSlice,
  vimeo: vimeoSlice,
  youtube: youtubeSlice,
  [vimeoApi.reducerPath]: vimeoApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(vimeoApi.middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
