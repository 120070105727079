import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export default function Logo() {
  const image = useSelector(
    (state: RootState) => state.content.content.logo.fields
  );
  return (
    <img
      src={image.file.url}
      alt={image.title}
      className="w-12 h-12 object-contain"
    />
  );
}
