import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllContent } from "./content.api";

export interface ContentState {
  content?: any;
}

const initialState: ContentState = {};

// Thunk actions
export const initialContent = createAsyncThunk(
  `content/loginStatus`,
  async () => {
    const response = await getAllContent();
    return response;
  }
);

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setContent: (state, action) => {
      state.content = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initialContent.fulfilled, (state, action) => {
      if (action.payload) {
        state.content = keysToExtract.reduce((acc, key) => {
          return {
            ...acc,
            [key]: findKeyFromObject(key, action.payload.items),
          };
        }, {});
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { setContent } = contentSlice.actions;

export default contentSlice.reducer;

const findKeyFromObject = (key: string, arr: any[]) => {
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.fields[key]) {
      return element.fields[key];
    }
  }
};

const keysToExtract: string[] = [
  "homemenu",
  "dashboardmenu",
  "logo",
  "homepage",
  "primary",
  "secondary",
  "textcolor",
  "welcome",
  "title",
  "subtitle",
  "linklabel",
  "linkplaceholder",
  "emaillabel",
  "emailplaceholder",
  "languagelabel",
  "languageplaceholder",
  "submitbutton",
  "linkerror",
  "emailerror",
  "languageerror",
  "autoupload_title",
  "autoupload_yes",
  "autoupload_no",
  "autoupload_authtoken_label",
  "autoupload_tokenplaceholder",
  "autoupload_submit",
  "loginEmailLabel",
  "loginEmailPlaceholder",
  "loginEmailError",
  "loginPasswordLabel",
  "loginPasswordPlaceholder",
  "loginPasswordError",
  "loginSubmitButton",
  "loginForgot",
  "loginRegister",
  "registerEmailLabel",
  "registerEmailPlaceholder",
  "registerEmailError",
  "registerPasswordLabel",
  "registerPasswordPlaceholder",
  "registerPasswordError",
  "registerFirstnameLabel",
  "registerFirstnamePlaceholder",
  "registerFirstnameError",
  "registerLastnameLabel",
  "registerLastnamePlaceholder",
  "registerLastnameError",
  "registerConfirmPasswordLabel",
  "registerConfirmPasswordPlaceholder",
  "registerConfirmPasswordError",
  "registerSubmitButton",
  "registerLoginMessage",
  "dashboardWelcomeMessage",
  "profileSettingsTitle",
  "profileSettingsEmailLabel",
  "profileSettingsEmailPlaceholder",
  "profileSettingsEmailError",
  "profileSettingsFirstNameLabel",
  "profileSettingsFirstNamePlaceholder",
  "profileSettingsFirstNameError",
  "profileSettingsLastNameLabel",
  "profileSettingsLastNamePlaceholder",
  "profileSettingsLastNameError",
  "profileSettingsTokenLabel",
  "profileSettingsTokenPlaceholder",
  "profileSettingsTokenError",
  "profileSettingsSubmitButton",
  "passswordSettingsPasswordLabel",
  "passswordSettingsPasswordPlaceholder",
  "passswordSettingsPasswordError",
  "passswordSettingsConfirmPasswordLabel",
  "passswordSettingsConfirmPasswordPlaceholder",
  "passswordSettingsConfirmPasswordError",
  "passswordSettingsSubmitButton",
];

export const colors: string[] = ["primary", "secondary", "textcolor"];
