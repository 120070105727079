import React, { FC } from "react";

type Props = {
  show: Boolean;
  setShow: (value: Boolean) => void;
  children: React.ReactNode;
};

const Modal: FC<Props> = (props) => {
  return props.show ? (
    <div
      onClick={() => props.setShow(false)}
      className="w-screen h-screen fixed top-0 left-0 bg-slate-900/50 flex items-center justify-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-full max-w-lg p-8 bg-white rounded-xl"
      >
        {props.children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Modal;
