import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faHourglass } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import api from "../../utils/aixos";
import languages from "../../constants/languages";
// import { useGetSubtitlesHistoryQuery } from "../../redux/services/vimeo";

function History() {
  // const { data, error, isLoading } = useGetSubtitlesHistoryQuery({});
  const [data, setData] = useState([]);

  const groupedData = useMemo(() => {
    return data.reduce((acc: any, item: any) => {
      const date = moment(item.date).format("DD/MM/YYYY");
      if (acc[date]) {
        acc[date].push(item);
      } else {
        acc[date] = [item];
      }
      return acc;
    }, {});
  }, [data]);

  const fetchData = async () => {
    const response = await api.get("/genrate-subtitles");
    setData(
      response.data.allSubtitles.map((e: any) => ({
        ...e,
        date: new Date(e.updatedAt || Date.now()),
        status:
          e.targetSubtitles && e.targetSubtitles.length >= 1
            ? "completed"
            : "pending",
      }))
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-8">
      <h1 className="font-bold text-3xl">History</h1>
      <div className="flex flex-col py-4">
        {Object.keys(groupedData).map((key) => {
          return (
            <>
              <h2 className="text-xl font-bold text-gray-500 mt-4">
                {moment().format("DD/MM/YYYY") === key
                  ? "Today"
                  : moment(key, "DD/MM/YYYY").format("dddd DD MMMM, YYYY")}
              </h2>
              {groupedData[key].map((item: any) => {
                return (
                  <div className=" flex items-center p-4 border-2 rounded-md my-3">
                    <div className="text-xs font-bold text-gray-400">
                      {moment(item.date).format("hh:mm A")}
                    </div>
                    <a
                      target={"_blank"}
                      href={item.vimeoUrl}
                      className="flex-1 px-3 text-primary"
                    >
                      {item.title}
                    </a>
                    <div className="w-40 flex items-center justify-center">
                      <div
                        className={`p-2 px-4 text-xs w-fit ${
                          item.status === "completed"
                            ? "bg-primary"
                            : "bg-gray-300"
                        } rounded-md font-bold text-white`}
                      >
                        {item.status}
                      </div>
                    </div>
                    {item.status === "completed" ? (
                      item.targetSubtitleLanguages.map(
                        (lang: string, index: number) => (
                          <a
                            download
                            target={"_blank"}
                            href={item.targetSubtitles[index]}
                            rel="noreferrer"
                            className="text-primary underline capitalize text-xs mr-2"
                          >
                            {languages.find((e) => e.code === lang)?.name}{" "}
                          </a>
                        )
                      )
                    ) : (
                      <FontAwesomeIcon
                        className="animate-spin"
                        icon={faHourglass}
                      />
                    )}
                  </div>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
}

const history: HistoryItem[] = [
  {
    date: new Date(),
    link: "https://github.com/",
    status: "completed",
    srtLink: "https://github.com/",
  },
  {
    date: new Date(),
    link: "https://github.com/",
    status: "pending",
  },
  {
    date: moment().subtract(2, "day").toDate(),
    link: "https://github.com/",
    status: "completed",
    srtLink: "https://github.com/",
  },
  {
    date: moment().subtract(2, "day").toDate(),
    link: "https://github.com/",
    status: "pending",
  },
  {
    date: moment().subtract(2, "day").toDate(),
    link: "https://github.com/",
    status: "completed",
    srtLink: "https://github.com/",
  },
  {
    date: new Date(),
    link: "https://github.com/",
    status: "pending",
  },
];

const historyGroupedByDate: GroupedHistory = history.reduce(
  (acc: GroupedHistory, item: HistoryItem) => {
    const date = moment(item.date).format("DD/MM/YYYY");
    if (acc[date]) {
      acc[date].push(item);
    } else {
      acc[date] = [item];
    }
    return acc;
  },
  {}
);

interface HistoryItem {
  date: Date;
  link: string;
  status: string;
  srtLink?: string;
}

interface GroupedHistory {
  [key: string]: HistoryItem[];
}

export default History;
