import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type ContentProps = {
  keys: string[];
};
export const Content = (props: ContentProps) => {
  const content = useSelector((state: RootState) =>
    props.keys.reduce((acc, key) => {
      return acc[key];
    }, state.content.content)
  );

  return <>{content}</>;
};
