import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import axios, { AxiosError } from "axios";
import { FC, ReactNode, useEffect, useMemo } from "react";
import { FieldValues, useForm } from "react-hook-form";
import Input from "../../components/Input";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Button from "../../components/Button";
import YoutubeLogo from "../../assets/images/logo/youtube.png";
import api from "../../utils/aixos";

const Settings = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const loginWithGoogle = async () => {
    try {
      const res = await api.post("/auth/google-auth");
      console.log(res.data);
      window.location.replace(res.data.url);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="p-8 flex flex-col lg:flex-row gap-4">
        <Card>
          <UpdateProfileForm />
        </Card>
        <Card>
          <UpdatePasswordForm />
        </Card>
      </div>
      <div className="p-8 flex flex-col lg:flex-row gap-4">
        <Card>
          <h2 className="font-bold text-lg mb-4">Connected Accounts</h2>
          {user?.googleOAuth?.access_token ? (
            <div className="flex items-center w-full">
              <img src={YoutubeLogo} alt="Youtube Logo" className="w-8 mr-2" />
              <div className="font-bold">
                Youtube Account has been connected
              </div>
            </div>
          ) : (
            <Button type="button" onClick={loginWithGoogle}>
              <div className="flex items-center w-full justify-center">
                <img
                  src={YoutubeLogo}
                  alt="Youtube Logo"
                  className="w-8 mr-2"
                />{" "}
                Connect Your Youtube Account
              </div>
            </Button>
          )}
        </Card>
      </div>
    </>
  );
};

const Card: FC<{ children: ReactNode }> = (props) => {
  return (
    <div className="basis-1/2 h-fit flex flex-col items-center p-4 border-2 rounded-lg">
      {props.children}
    </div>
  );
};

const UpdateProfileForm: FC = (props) => {
  const {
    profileSettingsTitle,
    profileSettingsEmailLabel,
    profileSettingsEmailPlaceholder,
    profileSettingsEmailError,
    profileSettingsFirstNameLabel,
    profileSettingsFirstNamePlaceholder,
    profileSettingsFirstNameError,
    profileSettingsLastNameLabel,
    profileSettingsLastNamePlaceholder,
    profileSettingsLastNameError,
    profileSettingsTokenLabel,
    profileSettingsTokenPlaceholder,
    profileSettingsTokenError,
    profileSettingsSubmitButton,
  } = useSelector((state: RootState) => state.content.content);

  const profileValidationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email("Email should be a valid email address")
          .required(profileSettingsEmailError),
        firstName: yup.string().required(profileSettingsFirstNameError),
        lastName: yup.string().required(profileSettingsLastNameError),
        vimeoAccessToken: yup.string().required(profileSettingsTokenError),
      }),
    [
      profileSettingsEmailError,
      profileSettingsFirstNameError,
      profileSettingsLastNameError,
      profileSettingsTokenError,
    ]
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(profileValidationSchema),
  });
  const token = useSelector((state: RootState) => state.auth.token);
  const user = useSelector((state: RootState) => state.auth.user);

  const fetchUser = async () =>
    reset({
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      vimeoAccessToken: user?.vimeoAccessToken,
    });

  useEffect(() => {
    if (user) fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const onSubmit = async (values: FieldValues) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/update-profile/`,
        {
          ...values,
        },
        {
          headers: { Authorization: String(token) },
        }
      );
      if (res.data.user._id) {
        showSuccessToast("Profile updated successfully!");
        fetchUser();
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        showErrorToast("Couldn't update profile!");
      }
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex flex-col items-center"
    >
      <h2 className="font-bold text-lg mb-4">{profileSettingsTitle}</h2>
      <div className="w-full pt-2 ">
        <Input
          type="text"
          register={register}
          name="email"
          error={errors?.email?.message?.toString() || ""}
          label={profileSettingsEmailLabel}
          placeholder={profileSettingsEmailPlaceholder}
        />
      </div>
      <div className="w-full pt-2 ">
        <Input
          type="text"
          register={register}
          name="firstName"
          error={errors?.firstName?.message?.toString() || ""}
          label={profileSettingsFirstNameLabel}
          placeholder={profileSettingsFirstNamePlaceholder}
        />
      </div>
      <div className="w-full pt-2 ">
        <Input
          type="text"
          register={register}
          name="lastName"
          error={errors?.lastName?.message?.toString() || ""}
          label={profileSettingsLastNameLabel}
          placeholder={profileSettingsLastNamePlaceholder}
        />
      </div>
      <div className="w-full pt-2 ">
        <Input
          type="text"
          register={register}
          name="vimeoAccessToken"
          error={errors?.vimeoAccessToken?.message?.toString() || ""}
          label={profileSettingsTokenLabel}
          placeholder={profileSettingsTokenPlaceholder}
        />
      </div>
      <Button disabled={isSubmitting}>
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : (
          profileSettingsSubmitButton
        )}
      </Button>
    </form>
  );
};

const UpdatePasswordForm: FC = (props) => {
  const {
    passswordSettingsPasswordLabel,
    passswordSettingsPasswordPlaceholder,
    passswordSettingsPasswordError,
    passswordSettingsConfirmPasswordLabel,
    passswordSettingsConfirmPasswordPlaceholder,
    passswordSettingsConfirmPasswordError,
    passswordSettingsSubmitButton,
  } = useSelector((state: RootState) => state.content.content);

  const passwordValidationSchema = useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .min(8, "Password should be min 8 characters")
          .required(passswordSettingsPasswordError),
        confirmPassword: yup
          .string()
          .min(8, "Password should be min 8 characters")
          .required(passswordSettingsConfirmPasswordError)
          .oneOf([yup.ref("password"), null], "Passwords must match"),
      }),
    [passswordSettingsPasswordError, passswordSettingsConfirmPasswordError]
  );
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(passwordValidationSchema) });
  const token = useSelector((state: RootState) => state.auth.token);
  const onSubmit = async (values: FieldValues) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/update-profile/`,
        {
          ...values,
        },
        {
          headers: { Authorization: String(token) },
        }
      );
      if (res.data.user._id) {
        showSuccessToast("Password updated successfully!");
        reset();
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        showErrorToast("Couldn't reset password!");
      }
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex flex-col items-center"
    >
      <h2 className="font-bold text-lg mb-4">Password Settings</h2>
      <div className="w-full pt-2 ">
        <Input
          type="password"
          register={register}
          name="password"
          error={errors?.password?.message?.toString() || ""}
          label={passswordSettingsPasswordLabel}
          placeholder={passswordSettingsPasswordPlaceholder}
        />
      </div>
      <div className="w-full pt-2 ">
        <Input
          type="password"
          register={register}
          name="confirmPassword"
          error={errors?.confirmPassword?.message?.toString() || ""}
          label={passswordSettingsConfirmPasswordLabel}
          placeholder={passswordSettingsConfirmPasswordPlaceholder}
        />
      </div>
      <Button disabled={isSubmitting}>
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : (
          passswordSettingsSubmitButton
        )}
      </Button>
    </form>
  );
};

export default Settings;
