import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import Input from "../components/Input";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosError } from "axios";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { Link } from "react-router-dom";
const ForgotPassword = () => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: (values: FieldValues) => Promise<void> = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/reset-password`,
        {
          ...values,
        }
      );
      if (res.data.msg) {
        showSuccessToast(res.data.msg);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        showErrorToast("Error sending email! Please check the email.");
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex items-center flex-col justify-center p-8"
    >
      <div className="w-full py-2 ">
        <Input
          type="text"
          register={register}
          name="email"
          error={errors?.email?.message?.toString() || ""}
          label="Email"
          placeholder="Please enter your email"
        />
      </div>
      <button
        disabled={isSubmitting}
        className="bg-primary w-full rounded-md p-4 my-4 text-white font-bold"
      >
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : (
          "Send Email"
        )}
      </button>
      <Link className="w-full text-center text-grey-100" to={"/login"}>
        Go back to login
      </Link>
    </form>
  );
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter an email"),
});

export default ForgotPassword;
