import api from "../../../utils/aixos";

const loginAPICall = async (email: string, password: string) =>
  api.post("/auth/login", { email, password });

const registerAPICall = async (
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  confirmPassword: string
) =>
  api.post("/auth/sign-up", {
    email,
    firstName,
    lastName,
    password,
    confirmPassword,
  });

const fetchUserAPICall = async () => api.get(`/auth/get-profile`);

export { loginAPICall, registerAPICall, fetchUserAPICall };
