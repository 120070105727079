export default function PrivacyPolicy() {
  return (
    <div className="p-8 ">
      <h1 className="text-3xl font-medium mb-4">Privacy Policy</h1>
      <p className="text-lg mb-4 leading-relaxed">
        At LetzChat, we are committed to protecting the privacy of our users.
        This privacy policy explains how we collect, use, and disclose
        information when you use our platform.
      </p>
      <h2 className="text-2xl font-medium mb-4">Information We Collect</h2>
      <p className="text-lg mb-4 leading-relaxed">
        When you use our platform, we may collect the following types of
        information:
      </p>
      <ul className="list-disc pl-4 text-lg mb-4">
        <li>
          Personal information, such as your name and email address, which you
          provide when you create an account or contact us.
        </li>
        <li>
          Vimeo and YouTube account access tokens, which we use to upload and
          edit subtitles on your behalf. These tokens are necessary to provide
          the service and will be stored securely on our servers.
        </li>
      </ul>
      <h2 className="text-2xl font-medium mb-4">Use of Information</h2>
      <p className="text-lg mb-4 leading-relaxed">
        We use the information we collect to provide, maintain, and improve our
        service. Specifically, we use your Vimeo and YouTube account access
        tokens to upload and edit subtitles on your behalf.
      </p>
      <p className="text-lg mb-4 leading-relaxed">
        We may also use your personal information to contact you with important
        information about our service, such as changes to our terms of service
        or privacy policy.
      </p>
      <h2 className="text-2xl font-medium mb-4">Disclosure of Information</h2>
      <p className="text-lg mb-4 leading-relaxed">
        We will not disclose your personal information to any third party
        without your consent, except in the following circumstances:
      </p>
      <ul className="list-disc pl-4 text-lg mb-4">
        <li>As required by law or to comply with a legal request.</li>
        <li>
          To protect the safety, rights, or property of ourselves or others.
        </li>
        <li>
          To detect, prevent, or otherwise address fraud, security, or technical
          issues.
        </li>
      </ul>
      <h2 className="text-2xl font-medium mb-4">Security</h2>
      <p className="text-lg mb-4 leading-relaxed">
        We take the security of your information seriously and have implemented
        measures to protect your information from unauthorized access, use, or
        disclosure. However, please note that no method of transmission or
        storage is completely secure, and we cannot guarantee the security of
        your information.
      </p>
      <h2 className="text-2xl font-medium mb-4">Changes to This Policy</h2>
      <p className="text-lg mb-4 leading-relaxed">
        We may update this policy from time to time. If we make changes, we will
        notify you by revising the date at the top of the policy and, in some
        cases, providing you with additional notice (such as adding a statement
        to our website’s homepage). We encourage you to review this policy
        periodically to stay informed about how we are protecting the personal
        information we collect.
      </p>
      <h2 className="text-2xl font-medium mb-4">Contact Us</h2>
      <p className="text-lg mb-4 leading-relaxed">
        If you have any questions about this privacy policy, please contact us
        by{" "}
        <a className="text-primary" href="contact@letzchat.com">
          contact@letzchat.com
        </a>
        .
      </p>
    </div>
  );
}
