import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPlaylistsAPICall } from "./youtube.api";

export interface Playlist {
  name: string;
  id: string;
}

export interface YoutubeState {
  playlists?: Playlist[];
}

const initialState: YoutubeState = {};

// Thunk actions
export const getPlaylists = createAsyncThunk(
  `playlists/PlaylistsStatus`,
  async () => {
    const response = await getPlaylistsAPICall();
    return response.data;
  }
);

export const youtubeSlice = createSlice({
  name: "youtube",
  initialState,
  reducers: {
    setPlaylists: (state, action) => {
      state.playlists = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlaylists.fulfilled, (state, action) => {
      if (action.payload) {
        state.playlists = action.payload.playlists;
      }
    });
  },
});

export default youtubeSlice.reducer;
