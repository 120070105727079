import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  FC,
  ReactNode,
  useDeferredValue,
  useMemo,
  useState,
} from "react";
import { Control, FieldValues, useController } from "react-hook-form";

interface SelectableListProps {
  control?: Control<FieldValues, any>;
  icon: ReactNode;
  options?: any[];
  labelKey: string;
  valueKey: string;
  name: string;
  label?: string;
  direction?: "col" | "row";
}

const SelectableList: FC<SelectableListProps> = (props) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name: props.name, control: props.control });
  const [query, setQuery] = useState("");
  const deferredQuery = useDeferredValue(query);
  const filteredOptions = useMemo(
    () =>
      props.options?.filter((option) =>
        String(option[props.labelKey]).includes(deferredQuery)
      ),
    [deferredQuery]
  );
  return (
    <div className="w-full">
      <div className="flex w-full items-center border p-2 mt-4 rounded ">
        <input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="flex-1 outline-0"
        />
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <div
        className={`flex flex-${
          props.direction || "col"
        } gap-3 my-4 max-h-40 overflow-y-scroll`}
      >
        {props.label && <label>{props.label}</label>}
        {filteredOptions?.length == 0 ? (
          <div>No results matching the query</div>
        ) : (
          filteredOptions?.map((option) => (
            <div
              key={option[props.valueKey]}
              className="flex w-full bg-gray-400/25 hover:bg-gray-400/10 cursor-pointer p-3 rounded-lg items-center"
              onClick={() => field.onChange(option)}
            >
              {props.icon}
              <span className="ml-2 font-semibold flex-1">
                {option[props.labelKey]}
              </span>
              {field.value &&
                field.value[props.valueKey] === option[props.valueKey] && (
                  <FontAwesomeIcon icon={faCheck} className="text-primary" />
                )}
            </div>
          ))
        )}
        {error && (
          <p className="text-red-500 font-medium mt-2 px-4">{error.message}</p>
        )}
      </div>
    </div>
  );
};

export default SelectableList;
