import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Input from "../../components/Input";
import languages, { liveLanguages } from "../../constants/languages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import api from "../../utils/aixos";
import { AxiosError } from "axios";
import { showErrorToast } from "../../utils/toast";

const Zoom = () => {
  const [loading, setLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState(false);

  const getStatusOfJob = async () => {
    const url = `https://api.video.letz.chat/api/zoom/status`;
    const response = await api.get(url);
    if (response.data.message === "Running") {
      setJobStatus(true);
    } else {
      setJobStatus(false);
    }
  };

  useEffect(() => {
    getStatusOfJob();
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      sourceLanguage: yup.object().required(),
      targetLanguage: yup.object().required(),
      captionApiToken: yup.string().required(),
    });
  }, []);
  const user = useSelector((store) => store?.auth?.user);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      data.sourceLanguage = data.sourceLanguage.value;
      data.targetLanguage = data.targetLanguage.value;
      const url = `https://api.video.letz.chat`;
      await api.post(`${url}/api/zoom`, {
        ...data,
      });
      setLoading(false);
      setJobStatus(true);
    } catch (error) {
      // if (error instanceof AxiosError) {
      //   showErrorToast(error.response?.data.error);
      // }
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="p-8">
      <div className="my-2">
        <div
          style={{
            fontFamily: "Paytone One",
            fontSize: 32,
          }}
          className="w-full lg:w-2/5"
        >
          Real-time subtitle Generation & Translation
        </div>
      </div>
      <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-3 ">
        <div>
          <p className="m-2">
            Our system is designed to make Zoom meetings more accessible and
            inclusive by automatically generating, translating, and rendering
            subtitles in real-time. With this system, you no longer need to
            worry about manually creating subtitles for your meetings, which can
            be time-consuming and prone to errors. Instead, our system
            automatically generates subtitles as the meeting progresses, and can
            even translate them into different languages for participants who
            prefer to read subtitles in their native language.
          </p>
          <p className="m-2">
            The subtitles are rendered directly on your screen, making it easy
            for everyone to follow along and participate in the meeting,
            regardless of their hearing ability or language proficiency. Our
            system is user-friendly and easy to set up, so you can focus on your
            meeting without worrying about technical details. We believe that
            everyone should have equal access to information and communication,
            and our system is designed to help achieve this goal.
          </p>
          <div>
            <div
              style={{
                fontFamily: "Paytone One",
                fontSize: 28,
              }}
              className="w-full"
            >
              Prerequisites
            </div>
            <ol className="m-2">
              <li>
                <strong>1.</strong> Zoom Pro Account
              </li>
              <li>
                <strong>2.</strong> Must Be Zoom Host
              </li>
              <li>
                <strong>3.</strong> Setup You Zoom For Get The Streaming of your
                meeting to transcribe it.{" "}
                <Link to="/zoom/configuration" className="text-blue-400">
                  Click Here To Steup
                </Link>
                .
              </li>
            </ol>
          </div>
          <div>
            <div
              style={{
                fontFamily: "Paytone One",
                fontSize: 28,
              }}
              className="w-full"
            >
              Your Info for streaming
            </div>
            <ol className="m-2">
              <li>
                <strong>Streaming URL: </strong> rtmp://stream.letz.chat/live
              </li>
              <li>
                <strong>Streaming key: </strong> {user && user?.zoomStreamToken}
              </li>
              <li>
                <strong>Live streaming page URL: </strong>{" "}
                https://www.video.letz.chat/zoom
              </li>
            </ol>
          </div>
        </div>
        <div className="m-2">
          <div
            style={{
              fontFamily: "Paytone One",
              fontSize: 18,
            }}
            className="w-full"
          >
            Letz Start. Please Make Sure You Have Read All Steps Before
            Starting.{" "}
            <Link to="/zoom/configuration" className="text-blue-400">
              Click Here
            </Link>{" "}
            to Read configuration and read about how to get Caption API Token.
          </div>
          <div>
            <form
              className="flex flex-col flex-1 justify-center py-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="w-full py-2 ">
                <Input
                  type="select"
                  control={control}
                  name="sourceLanguage"
                  error={errors?.sourceLanguage?.message?.toString() || ""}
                  label="Source Language"
                  options={liveLanguages.map((country) => ({
                    label: country.name,
                    value: country.code,
                  }))}
                  placeholder={"Please Select the Original Language of speaker"}
                />
              </div>
              <div className="w-full py-2 ">
                <Input
                  type="select"
                  control={control}
                  name="targetLanguage"
                  error={errors?.targetLanguage?.message?.toString() || ""}
                  label="Target Language"
                  options={languages.map((country) => ({
                    label: country.name,
                    value: country.code,
                  }))}
                  placeholder={
                    "Please Select the Language in which you want captions render"
                  }
                />
              </div>
              <div className="w-full py-2 ">
                <Input
                  type="text"
                  register={register}
                  name="captionApiToken"
                  error={errors?.captionApiToken?.message?.toString() || ""}
                  label={"Caption Api Token"}
                  placeholder={
                    "Please Provide Caption api token. Go to configuaration page to to read how to get it."
                  }
                />
              </div>
              <div className="w-full py-2">
                <button
                  disabled={loading || jobStatus}
                  className="bg-primary w-full rounded-md p-4 text-secondary font-bold"
                >
                  {jobStatus ? (
                    "Running! to stop end live streaming."
                  ) : loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin"
                    />
                  ) : (
                    "Start Transcription"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zoom;
