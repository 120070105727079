import React, { FC, useRef, useState } from "react";
import { Control, FieldValues, useController } from "react-hook-form";

import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../Button";

interface Props {
  placeholder?: string;
  name: string;
  control?: Control<FieldValues, any>;
  label: string;
  platform?: "youtube";
}

const MultiInput: FC<Props> = ({
  placeholder,
  name,
  control,
  platform,
  ...props
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
    defaultValue: [],
  });

  const ref = useRef<HTMLInputElement>(null);
  const [indexBeingEditted, setIndexBeingEditted] = useState<number>(-1);
  const [validationError, setValidationError] = useState<string>();

  const onSubmit = () => {
    const newValue = [...value];
    const regex = platform === "youtube" ? youtubeVideoRegex : vimeoVideoRegex;
    if (!regex.test(String(ref.current?.value))) {
      setValidationError("Please enter a valid video link");
      return;
    }
    if (indexBeingEditted >= 0) {
      newValue[indexBeingEditted] = ref.current?.value;
    } else {
      newValue.push(ref.current?.value);
    }
    onChange(newValue);
    if (ref.current) ref.current.value = "";
    setIndexBeingEditted(-1);
  };

  const onDelete = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    index: number
  ) => {
    if (indexBeingEditted === index) {
      setIndexBeingEditted(-1);
      if (ref.current) ref.current.value = "";
    }
    event.stopPropagation();
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  const onEdit = (link: string, index: number) => {
    if (indexBeingEditted === index) return;
    if (ref.current) {
      ref.current.value = link;
      setIndexBeingEditted(index);
    }
  };

  return (
    <>
      <div className="flex flex-col border rounded-md p-3 px-4">
        {props.label && (
          <label className="font-semibold mb-2 text-gray-400">
            {props.label}
          </label>
        )}
        <div className="flex">
          <input
            ref={ref}
            placeholder={placeholder}
            className="bg-transparent focus:outline-0 flex-1"
            onChange={() => setValidationError(undefined)}
          />
          <Button
            type="button"
            onClick={onSubmit}
            className=" bg-primary text-white rounded-full w-8 h-8 "
          >
            <FontAwesomeIcon icon={indexBeingEditted >= 0 ? faCheck : faPlus} />
          </Button>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 my-2">
        {value.map((link: string, index: number) => (
          <span
            className={`
            relative bg-primary ${
              index === indexBeingEditted ? "opacity-50" : ""
            } text-white p-1 px-2 cursor-pointer hover:opacity-70 transition-all rounded-3xl text-xs`}
            key={link}
            onClick={() => onEdit(link, index)}
          >
            {link}
            <FontAwesomeIcon
              icon={faTimes}
              className="ml-1"
              onClick={(e) => onDelete(e, index)}
            />
          </span>
        ))}
      </div>
      {error && (
        <p className="text-red-500 font-medium mt-2 px-4">{error.message}</p>
      )}
      {validationError && (
        <p className="text-red-500 font-medium mt-2 px-4">{validationError}</p>
      )}
    </>
  );
};

export default MultiInput;

const vimeoVideoRegex =
  /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

const youtubeVideoRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
