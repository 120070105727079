import { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import {
  Control,
  FieldValues,
  useController,
  UseFormRegister,
} from "react-hook-form";
import Select from "react-select";
import { formatBytes } from "../../utils/helper";
import "./input.css";
export type Option = {
  value: any;
  label: string;
};

type InputProps = {
  register?: UseFormRegister<FieldValues>;
  error?: string;
  control?: Control<FieldValues, any>;
  name: string;
  type: "text" | "password" | "select" | "checkbox" | "file";
  label?: string;
  placeholder?: string;
  options?: Option[];
  value?: any;
  [key: string]: any;
  onChange?: (value: any) => void;
};
type InputComponent = FC<InputProps>;

const Input: InputComponent = (props) => {
  return (
    <>
      <div className="flex flex-col border rounded-md p-3 px-4">
        {props.label && (
          <label className="font-semibold mb-2 text-gray-400">
            {props.label}
          </label>
        )}
        {["text", "password"].includes(props.type) ? (
          <TextInput {...props} />
        ) : props.type === "file" ? (
          <FileInput {...props} />
        ) : props.type === "checkbox" ? (
          <CheckboxInput {...props} />
        ) : (
          <SelectInput {...props} />
        )}
      </div>
      {props.error && (
        <p className="text-red-500 font-medium mt-2 px-4">{props.error}</p>
      )}
    </>
  );
};

const TextInput: InputComponent = ({
  register,
  name,
  placeholder,
  onChange,
  type,
}) => {
  return (
    <input
      {...(register && register(name))}
      {...(onChange && { onChange: (e) => onChange(e.currentTarget.value) })}
      placeholder={placeholder}
      type={type}
      className="bg-transparent focus:outline-0"
    />
  );
};

const FileInput: InputComponent = ({ control, name }) => {
  const { field } = useController({ control, name });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) field.onChange(acceptedFiles[0]);
    else field.onChange(null);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "video/mp4": [],
    },
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : field.value ? (
        <p>
          <strong className="mr-2 font-bold">{field.value.name}</strong>
          {formatBytes(field.value.size)}
        </p>
      ) : (
        <p>
          Drag some files here, or click to select files (Make sure the file is
          an MP4 file)
        </p>
      )}
    </div>
  );
};

const CheckboxInput: InputComponent = ({ control, name }) => {
  const { field } = useController({ control, name });
  return (
    <label className="switch">
      <input
        checked={field.value}
        onChange={(e) => field.onChange(e.target.checked)}
        type="checkbox"
      />
      <span className="slider round"></span>
    </label>
  );
};

const SelectInput: InputComponent = ({
  control,
  name,
  placeholder,
  ...props
}) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });
  return (
    <Select
      {...props}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      styles={{
        control: (base, state) => ({
          border: 0,
          display: "flex",
          padding: 0,
        }),
        valueContainer: () => ({
          padding: "10px 0",
          width: "100%",
          maxHeight: 40,
          display: "flex",
          alignItems: "center",
        }),
        multiValue: () => ({
          display: "flex",
          width: "fit-content",
          background: "rgba(76 ,29, 149,0.2)",
          borderRadius: 5,
          padding: "0 5px",
          alignItems: "center",
          margin: "0 3px",
        }),
        dropdownIndicator: () => ({ display: "none" }),
        indicatorSeparator: () => ({ display: "none" }),
      }}
      className="bg-transparent focus:outline-0 border-0"
    />
  );
};

export default Input;
