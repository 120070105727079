import { Store } from "@reduxjs/toolkit";
import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
});

export let store: Store;
export const injectStore = (_store: Store) => {
  store = _store;
};

api.interceptors.request.use((request) => {
  const token = store.getState().auth.token;
  if (request.headers) {
    request.headers["Authorization"] = String(token);
  }
  return request;
});

export default api;
