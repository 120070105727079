import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { logout } from "../../redux/slices/auth/auth.slice";
import { RootState } from "../../redux/store";
import Navbar from "./Navbar";

export default function DashboardLayout() {
  const dispatch = useDispatch();
  const dashboardNavbarContent = useSelector(
    (state: RootState) => state.content.content.dashboardmenu
  );
  const image = useSelector(
    (state: RootState) => state.content.content.homepage.fields
  );
  const { pathname } = useLocation();

  const routes = [
    {
      path: "/zoom",
      label: "Zoom",
    },
    {
      path: "/vimeo",
      label: "Vimeo",
    },
    {
      path: "/youtube",
      label: "YouTube",
    },
    {
      path: "/history",
      label: "History",
    },
    {
      path: "/settings",
      label: dashboardNavbarContent[1],
    },
    {
      path: "/payment",
      label: "Payment",
    },
    {
      path: "#",
      label: dashboardNavbarContent[2],
      onClick: () => dispatch(logout()),
    },
  ];
  return (
    <>
      <div className="flex flex-col lg:flex-row-reverse w-screen h-full">
        {pathname === "/" && (
          <div
            className="h-full hidden lg:flex lg:w-1/2 2xl:w-5/12 "
            style={{
              backgroundImage: `url(${image.file.url})`,
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                opacity: 0.6,
              }}
              className="bg-primary"
            ></div>
          </div>
        )}

        <div
          className={
            pathname === "/"
              ? "h-full flex flex-col lg:w-1/2 2xl:w-7/12 overflow-scroll"
              : "h-full w-full flex flex-col overflow-scroll"
          }
        >
          <Navbar routes={routes} />
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
