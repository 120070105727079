import api from "../../../utils/aixos";

export const getFoldersAPICall = async () => api.get("/vimeo/get-folders");

export const translateFoldersAPICall = async ({
  folderId,
  targetLanguages,
  email,
  accessToken,
}: {
  folderId: string;
  targetLanguages: string[];
  email: string;
  accessToken: string;
}) =>
  api.post("/genrate-subtitles/folder", {
    folderId,
    targetLanguages,
    email,
    accessToken,
  });

export const bulkTranslateAPICall = async ({
  videoUrlArray,
  targetLanguages,
  email,
  accessToken,
}: {
  videoUrlArray: string[];
  targetLanguages: string[];
  email: string;
  accessToken: string;
}) =>
  api.post("/genrate-subtitles/bulk", {
    videoUrlArray,
    targetLanguages,
    email,
    accessToken,
  });

export const autoUploadAPICall = async ({
  isTrue,
  targetLanguages,
}: {
  isTrue: boolean;
  targetLanguages: string[];
}) =>
  api.post("/genrate-subtitles/auto-genrate", {
    isTrue,
    targetLanguages,
  });
