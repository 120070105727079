import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import Input from "../components/Input";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosError } from "axios";
import { Link } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { setAuth } from "../redux/slices/auth/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useMemo } from "react";
const Login = () => {
  const dispatch = useDispatch();
  const {
    loginEmailLabel,
    loginEmailPlaceholder,
    loginEmailError,
    loginPasswordLabel,
    loginPasswordPlaceholder,
    loginPasswordError,
    loginSubmitButton,
    loginForgot,
    loginRegister,
  } = useSelector((state: RootState) => state.content.content);

  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup.string().email(loginEmailError).required(loginEmailError),
        password: yup
          .string()
          .min(8, "Password should be min 8 characters")
          .required(loginPasswordError),
      }),
    [loginEmailError, loginPasswordError]
  );

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: (values: FieldValues) => Promise<void> = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/login`,
        {
          ...values,
        }
      );
      if (res.data.token) {
        showSuccessToast("Login successfull!");
        dispatch(
          setAuth({
            token: res.data.token,
            user: res.data,
          })
        );
      } else {
        showErrorToast(res?.data?.message);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        console.log(error.response?.data?.message);
        showErrorToast(error.response?.data?.message);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex items-center flex-col justify-center p-8"
    >
      <div className="w-full py-2 ">
        <Input
          type="text"
          register={register}
          name="email"
          error={errors?.email?.message?.toString() || ""}
          label={loginEmailLabel}
          placeholder={loginEmailPlaceholder}
        />
      </div>
      <div className="w-full pt-2 ">
        <Input
          type="password"
          register={register}
          name="password"
          error={errors?.password?.message?.toString() || ""}
          label={loginPasswordLabel}
          placeholder={loginPasswordPlaceholder}
        />
      </div>
      <Link
        className="w-full text-right text-grey-100 mb-2"
        to={"/forgot-password"}
      >
        {loginForgot}
      </Link>
      <button
        disabled={isSubmitting}
        className="bg-primary w-full rounded-md p-4 my-4 text-white font-bold"
      >
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : (
          loginSubmitButton
        )}
      </button>
      <Link className="w-full text-center text-grey-100" to={"/register"}>
        {loginRegister}
      </Link>
    </form>
  );
};

export default Login;
