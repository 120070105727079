import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Footer = () => {
  const { token: accessToken } = useSelector((state: RootState) => ({
    user: state.auth.user,
    token: state.auth.token,
  }));
  return (
    <footer className="bg-primary text-white font-bold p-4">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div className="text-sm">
            <a href="/privacy-policy" className=" text-white font-bold mr-4">
              Privacy Policy
            </a>
            <a href="/terms-conditions" className=" text-white font-bold mr-4">
              Terms and Conditions
            </a>
            {!accessToken && (
              <a href="/pricing-plan" className=" text-white font-bold mr-4">
                Pricing
              </a>
            )}
          </div>
          <div>
            <p className="text-sm">Copyright ©2023 LetzChat</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
