import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import languages from "../constants/languages";
import Input, { Option } from "../components/Input";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheckCircle,
  faExclamationCircle,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import Modal from "../components/Modal";
import { Content } from "../components/Content";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import YoutubeLogo from "../assets/images/logo/youtube.png";
import VimeoLogo from "../assets/images/logo/vimeo.png";
import Tabs from "../components/Tabs";
import api from "../utils/aixos";
import TopLoader from "../components/Top-Loader";
import { useNavigate } from "react-router-dom";

type ResponseMessage = {
  message: string;
  type: "success" | "error";
};

export default function Home() {
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState<
    ResponseMessage | undefined
  >();
  const [authToken, setAuthToken] = useState<String>("");
  const [data, setData] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showAutoUpploadModal, setShowAutoUpploadModal] =
    useState<Boolean>(false);
  const [showAuthTokenModal, setShowAuthTokenModal] = useState<Boolean>(false);
  const { user, token: accessToken } = useSelector((state: RootState) => ({
    user: state.auth.user,
    token: state.auth.token,
  }));

  const {
    linklabel,
    linkplaceholder,
    emaillabel,
    emailplaceholder,
    languagelabel,
    languageplaceholder,
    submitbutton,
    linkerror,
    emailerror,
    languageerror,
    autoupload_title,
    autoupload_yes,
    autoupload_no,
    autoupload_authtoken_label,
    autoupload_tokenplaceholder,
  } = useSelector((state: RootState) => state.content.content);
  const validationSchema = useMemo(() => {
    return yup.object({
      link: yup.string().url(),
      email: yup.string().email(emailerror).required(emailerror),
      languagesToConvert: yup.mixed().required(languageerror),
    });
  }, [linkerror, emailerror, languageerror]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: (values: FieldValues) => Promise<void> = async (values) => {
    console.log(values);
    setData(values);
  };

  useEffect(() => {
    if (!data) return;
    if (data.platform === "vimeo" && user?.vimeoAccessToken) {
      generateSubtitles(user?.vimeoAccessToken);
    }
    if (data.platform === "youtube") {
      generateSubtitles(user?.vimeoAccessToken);
    } else if (data.platform === "local-video") {
      console.log("this b");
      generateSubtitleLocal();
    } else {
      setShowAutoUpploadModal(true);
    }
  }, [data]);

  const generateSubtitleLocal = async () => {
    try {
      setLoading(true);
      console.log("generating subtitle for video");
      const formData = new FormData();
      formData.append("videoFile", data.file);
      formData.append("targetLanguage", data.languagesToConvert.value);
      formData.append("email", data.email);
      console.log(Array.from(formData.values()));
      await api.post(`/genrate-local-video-subtitles`, formData);
      setResponseMessage({
        message: "Translation initiated! You will recieve an email in a while.",
        type: "success",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setResponseMessage({
        message: "Translation Failed! Please verify your input and try again.",
        type: "error",
      });
    }
  };

  const generateSubtitles = async (token?: string) => {
    try {
      setLoading(true);
      await api.post(
        `${process.env.REACT_APP_BASE_URL}/api/${
          data.platform === "youtube"
            ? "genrate-youtube-subtitles"
            : "genrate-subtitles"
        }`,
        {
          videoUrl: data.link,
          targetLanguages: data.languagesToConvert.map(
            (lang: Option) => lang.value
          ),
          token: user?.googleOAuth?.access_token ? accessToken : undefined,
          email: data.email,
          accessToken: token ? token : authToken || undefined,
        }
      );
      setResponseMessage({
        message: "Translation initiated! You will recieve an email in a while.",
        type: "success",
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      console.log("hello", error?.response?.data?.error);
      setResponseMessage({
        message: error?.response?.data?.error,
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (responseMessage) {
      setTimeout(() => setResponseMessage(undefined), 20000);
    }
  }, [responseMessage]);

  const platform = watch("platform");
  return (
    <>
      {!accessToken ? (
        <>
          <div className="flex justify-center p-16">
            <div className="w-60% p-6 flex-1">
              <h1 className="text-5xl font-medium my-8 text-primary">
                Hi there!
              </h1>
              <h1 className="text-primary text-3xl font-medium my-8 mb-8">
                Welcome to our subtitle generation and upload service!
              </h1>
              <p className="text-lg my-8 leading-relaxed">
                Our platform is designed to help content creators easily add
                subtitles to their videos in multiple languages. Our
                state-of-the-art subtitle generator uses advanced algorithms to
                automatically generate subtitles with high accuracy, saving you
                the time and effort of manually transcribing your videos.
              </p>
              <p className="text-lg my-8 leading-relaxed">
                With our platform, you can also easily upload your generated
                subtitles to popular video hosting platforms such as Vimeo and
                YouTube, making it simple to reach a global audience.
              </p>
              <p className=" text-lg my-8 leading-relaxed">
                In addition, we also offer the option to burn the subtitles
                directly onto your local videos, allowing you to create
                professional-looking videos without the need for any additional
                software.
              </p>
              <button
                className="bg-primary text-white px-4 py-2 rounded-lg mb-8"
                onClick={() => navigate("/login")}
              >
                Get Started
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading && <TopLoader loading={loading} />}
          <div className="px-8 flex flex-col h-full">
            <div className="lg:min-h-[300px] flex flex-col justify-center w-full lg:w-5/6  mt-3">
              <div className="bg-primary w-fit text-secondary rounded-full p-2 px-4 text-xs font-bold">
                <Content keys={["welcome"]} />
              </div>
              <div className="my-2 w-full lg:w-5/6">
                <div
                  style={{
                    fontFamily: "Paytone One",
                    fontSize: 32,
                  }}
                >
                  <Content keys={["title"]} />
                </div>
              </div>
              <div className="mt-4 font-semibold">
                <Content keys={["subtitle"]} />
              </div>
            </div>

            <form
              className="flex flex-col flex-1 justify-center py-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              {responseMessage && (
                <div
                  className={
                    responseMessage?.type === "success"
                      ? "flex items-center bg-green-100 w-full p-4 rounded-md "
                      : "flex items-center bg-red-100 w-full p-4 rounded-md "
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      responseMessage.type === "success"
                        ? faCheckCircle
                        : faExclamationCircle
                    }
                    size="2x"
                    color="white"
                  />
                  <p className="ml-6 font-semibold ">
                    {responseMessage.message}
                  </p>
                </div>
              )}
              <Tabs
                control={control}
                name="platform"
                label="Platform"
                options={[
                  {
                    icon: (
                      <img src={VimeoLogo} alt="vimeo" className="w-8 mr-2" />
                    ),
                    label: "Vimeo",
                    value: "vimeo",
                  },
                  {
                    icon: (
                      <img
                        src={YoutubeLogo}
                        alt="youtube"
                        className="w-8 mr-2"
                      />
                    ),
                    label: "Youtube",
                    value: "youtube",
                  },
                  {
                    icon: (
                      <FontAwesomeIcon
                        icon={faVideo}
                        className="w-8 text-xl text-primary mr-2"
                      />
                    ),
                    label: "Local Video",
                    value: "local-video",
                  },
                ]}
              />
              {platform === "local-video" ? (
                <div className="w-full py-2 ">
                  <Input
                    type="file"
                    control={control}
                    name="file"
                    error={errors?.file?.message?.toString() || ""}
                    label={"Selet a File"}
                    required
                    placeholder={"Please select a file"}
                  />
                </div>
              ) : (
                <div className="w-full py-2 ">
                  <Input
                    type="text"
                    register={register}
                    name="link"
                    error={errors?.link?.message?.toString() || ""}
                    label={linklabel}
                    placeholder={linkplaceholder}
                  />
                </div>
              )}
              <div className="w-full py-2 ">
                <Input
                  type="text"
                  register={register}
                  name="email"
                  error={errors?.email?.message?.toString() || ""}
                  label={emaillabel}
                  placeholder={emailplaceholder}
                />
              </div>
              <div className="w-full py-2 ">
                <Input
                  type="select"
                  control={control}
                  name="languagesToConvert"
                  error={errors?.languagesToConvert?.message?.toString() || ""}
                  label={languagelabel}
                  options={languages.map((country) => ({
                    label: country.name,
                    value: country.code,
                  }))}
                  placeholder={languageplaceholder}
                  isMulti={platform !== "local-video"}
                />
              </div>
              <div className="w-full py-2">
                <button
                  disabled={loading}
                  className="bg-primary w-full rounded-md p-4 text-secondary font-bold"
                >
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin"
                    />
                  ) : (
                    submitbutton
                  )}
                </button>
              </div>
            </form>
            <Modal
              show={showAutoUpploadModal}
              setShow={setShowAutoUpploadModal}
            >
              <div>
                <p className="font-bold text-xl mb-4">
                  Do You Want to Upload Subtitles to Vimeo
                </p>
                <button
                  onClick={() => {
                    setShowAuthTokenModal(true);
                    setShowAutoUpploadModal(false);
                  }}
                  className="bg-primary w-full mb-2  rounded-md p-4 px-6 text-secondary font-bold"
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setShowAutoUpploadModal(false);
                    generateSubtitles();
                  }}
                  className="bg-gray-400 w-full  rounded-md p-4 px-6 text-secondary font-bold"
                >
                  No
                </button>
              </div>
            </Modal>
            <Modal show={showAuthTokenModal} setShow={setShowAuthTokenModal}>
              <div>
                <p className="font-bold text-xl">Enter Your Auth Token</p>
                <div className="w-full py-4 ">
                  <Input
                    name="authToken"
                    type="text"
                    onChange={setAuthToken}
                    label={autoupload_authtoken_label}
                    placeholder={autoupload_tokenplaceholder}
                  />
                </div>
                <button
                  onClick={() => {
                    setShowAuthTokenModal(false);
                    generateSubtitles();
                  }}
                  className="bg-primary w-full rounded-md p-4 px-6 text-secondary font-bold"
                >
                  Submit
                </button>
              </div>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}
