import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Footer from "../../components/Footer/Footer";

export default function HomeLayout() {
  const homeNavbarContent = useSelector(
    (state: RootState) => state.content.content.homemenu
  );
  const image = useSelector(
    (state: RootState) => state.content.content.homepage.fields
  );
  const routes = [
    {
      path: "/",
      label: homeNavbarContent[0],
    },
    {
      path: "/login",
      label: homeNavbarContent[1],
    },
  ];

  return (
    <>
      <div className="flex flex-col lg:flex-row-reverse w-screen h-full">
        <div
          className="h-full hidden lg:flex lg:w-1/2 2xl:w-5/12 "
          style={{
            backgroundImage: `url(${image.file.url})`,
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0.6,
            }}
            className="bg-primary"
          ></div>
        </div>
        <div className="h-full flex flex-col lg:w-1/2 2xl:w-7/12 overflow-scroll">
          <Navbar routes={routes} />
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
