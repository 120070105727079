import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFoldersAPICall } from "./vimeo.api";

export interface Folder {
  name: string;
  id: string;
}

export interface VimeoState {
  folders?: Folder[];
}

const initialState: VimeoState = {};

// Thunk actions
export const initialFolders = createAsyncThunk(
  `folders/foldersStatus`,
  async () => {
    const response = await getFoldersAPICall();
    return response.data;
  }
);

export const vimeoSlice = createSlice({
  name: "vimeo",
  initialState,
  reducers: {
    setFolders: (state, action) => {
      state.folders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initialFolders.fulfilled, (state, action) => {
      if (action.payload) {
        state.folders = action.payload;
      }
    });
  },
});

export default vimeoSlice.reducer;
