import React from "react";
import { useNavigate } from "react-router-dom";

const PricingPlan = () => {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto px-4 p-8 m-8">
      <h1 className="text-center text-3xl font-medium">
        Welcome to LetzChat Subtitle Generator
      </h1>
      <p className="text-center text-gray-600">
        Your one-stop solution for all your subtitle needs
      </p>
      <div className="flex flex-col md:flex-row md:justify-center mt-8">
        <div className="bg-white rounded-lg p-6 shadow-md flex flex-col">
          <h3 className="text-lg font-medium text-center">Standard</h3>
          <div className="pt-4">
            <div className="text-sm text-gray-600">
              $0.0146 per second for YouTube videos
            </div>
            <div className="text-sm text-gray-600">
              Free for uploading to your own YouTube or Vimeo video
            </div>
            <div className="text-sm text-gray-600">
              $0.02 per second for local video
            </div>
            <div className="text-sm text-gray-600">
              $20 fixed for every month
            </div>
          </div>
          <div>
            <div className="text-sm text-primary ">
              20 Minutes Free Monthly.
            </div>
          </div>
          <div className="pt-4 text-center">
            <button
              className="bg-primary text-white py-2 px-4 rounded-lg "
              onClick={() => navigate("/register")}
            >
              Choose Plan
            </button>
          </div>
        </div>
      </div>
      <p className="text-center text-gray-600 mt-4">
        If you have any questions or concerns, please don't hesitate to contact
        us at{" "}
        <a className="text-primary" href="contact@letzchat.com">
          contact@letzchat.com
        </a>
        .
      </p>
      <p className="text-center text-gray-600">
        Thank you for choosing LetzChat Subtitle Generator.
      </p>
    </div>
  );
};

export default PricingPlan;
