import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import Input from "../components/Input";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosError } from "axios";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { FC, useMemo, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Button from "../components/Button";

interface FormProps {
  next: (data: any) => void;
  prev?: () => void;
}

interface PersonalInformation {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

const PersonalInformationFrom: FC<FormProps> = ({ next }) => {
  const {
    registerEmailLabel,
    registerEmailPlaceholder,
    registerEmailError,
    registerPasswordLabel,
    registerPasswordPlaceholder,
    registerPasswordError,
    registerFirstnameLabel,
    registerFirstnamePlaceholder,
    registerFirstnameError,
    registerLastnameLabel,
    registerLastnamePlaceholder,
    registerLastnameError,
    registerConfirmPasswordLabel,
    registerConfirmPasswordPlaceholder,
    registerConfirmPasswordError,
    registerSubmitButton,
    registerLoginMessage,
  } = useSelector((state: RootState) => state.content.content);

  const validationSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email("Please enter a valid email")
          .required(registerEmailError),
        firstName: yup.string().trim().required(registerFirstnameError),
        lastName: yup.string().trim().required(registerLastnameError),
        password: yup
          .string()
          .min(8, "Password should be min 8 characters")
          .required(registerPasswordError),
        confirmPassword: yup
          .string()
          .min(8, "Password should be min 8 characters")
          .required(registerConfirmPasswordError)
          .oneOf([yup.ref("password"), null], "Passwords must match"),
      }),
    [
      registerEmailError,
      registerPasswordError,
      registerFirstnameError,
      registerLastnameError,
      registerConfirmPasswordError,
    ]
  );

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: (values: FieldValues) => Promise<void> = async (values) => {
    const email = values?.email;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/confirm-email`,
        {
          email,
        }
      );
      console.log(res.data, "success");
      if (res.data.message) {
        next(values);
      } else {
        showErrorToast("Email is Already Used.");
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        console.log(error.response?.data, "axios error");
        showErrorToast(error.response?.data?.error);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex items-center flex-col justify-center p-8"
    >
      <div className="w-full py-2 ">
        <Input
          type="text"
          register={register}
          name="firstName"
          error={errors?.firstName?.message?.toString() || ""}
          label={registerFirstnameLabel}
          placeholder={registerFirstnamePlaceholder}
        />
      </div>
      <div className="w-full py-2 ">
        <Input
          type="text"
          register={register}
          name="lastName"
          error={errors?.lastName?.message?.toString() || ""}
          label={registerLastnameLabel}
          placeholder={registerLastnamePlaceholder}
        />
      </div>
      <div className="w-full py-2 ">
        <Input
          type="text"
          register={register}
          name="email"
          error={errors?.email?.message?.toString() || ""}
          label={registerEmailLabel}
          placeholder={registerEmailPlaceholder}
        />
      </div>
      <div className="w-full pt-2 ">
        <Input
          type="password"
          register={register}
          name="password"
          error={errors?.password?.message?.toString() || ""}
          label={registerPasswordLabel}
          placeholder={registerPasswordPlaceholder}
        />
      </div>
      <div className="w-full py-2 ">
        <Input
          type="password"
          register={register}
          name="confirmPassword"
          error={errors?.confirmPassword?.message?.toString() || ""}
          label={registerConfirmPasswordLabel}
          placeholder={registerConfirmPasswordPlaceholder}
        />
      </div>
      <button
        disabled={isSubmitting}
        className="bg-primary w-full rounded-md p-4 my-4 text-white font-bold"
      >
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : (
          registerSubmitButton
        )}
      </button>
      <Link className="w-full text-center text-grey-100" to={"/login"}>
        {registerLoginMessage}
      </Link>
    </form>
  );
};

const PaymentInformationFrom: FC<FormProps> = ({ next, prev }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isProcessing, setIsProcessing] = useState(false);
  const { registerLoginMessage } = useSelector(
    (state: RootState) => state.content.content
  );
  const { handleSubmit } = useForm();

  const onSubmit: (values: FieldValues) => Promise<void> = async (values) => {
    setErrorMessage("");
    setIsProcessing(true);
    const response = await stripe?.createPaymentMethod({
      type: "card",
      card: elements?.getElement(CardElement)!,
    });
    if (response?.error) {
      setErrorMessage(response?.error?.message);
      setIsProcessing(false);
    } else {
      next({
        paymentMethod: response?.paymentMethod?.id,
      });
      setIsProcessing(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex items-center flex-col justify-center"
    >
      <div className="w-4/5 mx-2 bg-white p-6 rounded-lg shadow-md border border-gray-300">
        <h2 className="text-lg font-medium mb-4 text-center">Checkout</h2>
        <div className="mb-4">
          <div className="text-gray-700 font-medium">Monthly fixed price</div>
          <div className="text-xl font-medium">$20</div>
        </div>
        <div className="mb-4">
          <div className="text-gray-700 font-medium">
            Subtitle Generator Email
          </div>
          <div className="text-xl font-medium">$0.0146/second</div>
        </div>
        <div className="mb-4">
          <div className="text-gray-700 font-medium">
            Generated Subtitles upload to platform
          </div>
          <div className="text-xl font-medium">Free</div>
        </div>
        <div className="mb-4">
          <div className="text-gray-700 font-medium">
            Local Video burn subtitle
          </div>
          <div className="text-xl font-medium">$0.02/second</div>
        </div>
        <div className="mb-4">
          <div className="text-gray-700 font-medium">
            * Free for the first 20 minutes
          </div>
        </div>
        <div className="mb-4">
          <CardElement
            className="p-4 rounded-lg"
            options={{ style: { base: { fontSize: "16px" } } }}
          />
          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        </div>
      </div>
      <button
        disabled={isProcessing}
        className="bg-primary  w-full rounded-md p-4 mt-4  text-white font-bold"
        type="submit"
      >
        {isProcessing ? (
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : (
          "Subscribe & Pay $20"
        )}
      </button>
      <Button
        type="button"
        onClick={prev}
        className="bg-gray-300  w-full rounded-md p-4 mb-4 mt-1 text-white font-bold"
      >
        Go Back
      </Button>

      <Link className="w-full text-center text-grey-100" to={"/login"}>
        {registerLoginMessage}
      </Link>
    </form>
  );
};

const Register = () => {
  const [step, setStep] = useState<number>(0);
  const [personalInfo, setPersonalInfo] = useState<PersonalInformation>();
  const stripe = useStripe();
  const navigate = useNavigate();

  const onSubmit: (values: FieldValues) => Promise<void> = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/sign-up`,
        {
          ...values,
          ...personalInfo,
        }
      );
      if (res.data.succes) {
        showSuccessToast(
          "Account successfully created! Please check your email."
        );
        const confirmPayment = await stripe?.confirmCardPayment(
          res.data.clientSecret
        );
        if (confirmPayment?.error) {
          showErrorToast(confirmPayment?.error?.message || "");
        } else {
          showSuccessToast("Success! Check your email for the invoice.");
          navigate("/login");
        }
      } else {
        showErrorToast("Account creation failed!");
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        console.log(error.response?.data);
        showErrorToast(error.response?.data?.message);
      }
    }
  };

  const saveDataAndState = (data: any) => {
    setPersonalInfo(data);
    setStep(1);
  };

  const setPrevStep = () => {
    setStep(0);
  };

  return (
    <>
      {step === 0 ? (
        <PersonalInformationFrom next={saveDataAndState} />
      ) : (
        <PaymentInformationFrom next={onSubmit} prev={setPrevStep} />
      )}
    </>
  );
};

export default Register;
