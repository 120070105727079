import React, { FC, ReactNode } from "react";
import { Control, FieldValues, useController } from "react-hook-form";

type Option = {
  label: string;
  icon: ReactNode;
  value: string;
};
type Props = {
  options: Option[];
  control: Control<FieldValues, any>;
  name: string;
  label?: string;
};
const Tabs: FC<Props> = ({ name, control, label, options }) => {
  const {
    field: { value, onChange },
  } = useController({ name, control, defaultValue: options[0].value });
  return (
    <div className="w-full py-2 flex flex-row gap-4">
      {options.map((option) => (
        <div
          className={`flex items-center transition-all ${
            value === option.value
              ? "bg-gray-100 border-primary border shadow-md"
              : "bg-white border"
          }  p-2 px-3 rounded-md cursor-pointer`}
          onClick={() => onChange(option.value)}
        >
          {option.icon}
          <div className="font-bold">{option.label}</div>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
